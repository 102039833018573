import React from 'react'
import Icon from '../misc/Icons'

class AccountButton extends React.Component {
  render() {
    const {
      onClickFunction, label, name, children, icon,
    } = this.props
    return (
      <button
        className={`btn btn--mid account-accountBtn${
          name ? ` account-accountBtn--${name}` : ''}${this.props.subtle ? ' account-accountBtn--subtle' : ''}`}
        onClick={onClickFunction}
      >
        {icon && <Icon name={icon} />}
        {label}
        {children}
      </button>
    )
  }
}

export default AccountButton
