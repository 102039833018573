import React from 'react'
import Icon from '../misc/Icons'
import SectionMarker from './SectionMarker'

class DropdownItem extends React.Component {
  handleOnClick(e) {
    e.stopPropagation()
    this.props.onSelect()
  }

  render() {
    const {
      label, itemType, section, warning, isSubHeader, disabled,
    } = this.props
    if (itemType === 'AddToClass') {
      return (
        <div className='dropdownMenu-item dropdownMenu-item--section dropdownMenu-item--addToClass' onClick={this.handleOnClick.bind(this)}>
          <SectionMarker section={section} />
          <Icon name='plus' />
          <SectionMarker section={section} type='colorOnly' />
        </div>
      )
    } if (itemType === 'Play') {
      return (
        <div className='dropdownMenu-item dropdownMenu-item--section dropdownMenu-item--play' onClick={this.handleOnClick.bind(this)}>
          <SectionMarker section={section} />
          <Icon name='play' />
          <SectionMarker section={section} type='colorOnly' />
        </div>
      )
    } if (itemType === 'RemoveFromClass') {
      return (
        <div className='dropdownMenu-item dropdownMenu-item--removeFromClass' onClick={this.handleOnClick.bind(this)}>
          <SectionMarker section={section} />
          <Icon name='close' />
        </div>
      )
    } if (itemType === 'ccpaNotification') {
      return (
        <div className='dropdownMenu-item smallText withIcon' onClick={this.handleOnClick.bind(this)}>
          <Icon name='ccpa' />
          {label}
        </div>
      )
    }
    return (
      <div className={`dropdownMenu-item${warning ? ' is--warning' : ''}${disabled ? ' is--disabled' : ''}${isSubHeader ? ' is--subHeader' : ''}`} onClick={this.handleOnClick.bind(this)}>
        {label}
      </div>
    )
  }
}

export default DropdownItem
