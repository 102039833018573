import React from 'react'
import Icon from '../misc/Icons'
import SectionMarker from '../uiKit/SectionMarker'
import LiveViewDropdown from './LiveViewDropdown'
import LiveViewModeSwitchDropdown from './LiveViewModeSwitchDropdown'
import SyncLiveViewToolbarScanCountdown from './sync/SyncLiveViewToolbarScanCountdown'
import SyncLiveViewToolbarScanStopwatch from './sync/SyncLiveViewToolbarScanStopwatch'
import { analyticsNowPlayingOpenDropdown } from '../../utils/analytics/analyticsNowPlayingOpenDropdown'

class SectionDataOwnerLabel extends React.Component {
  render() {
    const { dataOwner } = this.props
    return (
      <div className='liveView-sectionDataOwnerLabel'>
        <Icon name='organization' />
        <div className='liveView-sectionDataOwnerLabel-name notranslate'>{dataOwner.shortName}</div>
      </div>
    )
  }
}

// Toolbar at the top on live view with settings and mode switch dropdowns, minimise and close butttons etc
class LiveViewToolBar extends React.Component {
  constructor() {
    super()
    this.onDropdownOpen = this.onDropdownOpen.bind(this)
    this.state = {
      settingsDropdownOpen: false,
      modeSwitchDropdownOpen: false,
    }
  }

  delayedOnDropdownClose(dropdown) {
    setTimeout(() => { // Wait for animation to finish before set state
      if (dropdown === 'settings') {
        this.setState({ settingsDropdownOpen: false })
      } else if (dropdown === 'modeSwitch') {
        this.setState({ modeSwitchDropdownOpen: false })
      }
    }, 200)
  }

  onDropdownOpen(dropdown) { // dropdown= studentList/displayOptions/modeSwitch
    if (dropdown === 'modeSwitch') {
      this.setState({ modeSwitchDropdownOpen: true })
    } else {
      this.setState({ settingsDropdownOpen: true })
    }
    analyticsNowPlayingOpenDropdown(dropdown)
  }

  render() {
    const {
      minimiseLiveView,
      section,
      sectionDataOwner,
      stopLiveView,
      isEmptyState,
      toolBarHeight,
      nowPlayingMode,
    } = this.props
    const { settingsDropdownOpen, modeSwitchDropdownOpen } = this.state
    let sectionSyncActivated = false
    if (section && section.studentAccess && section.studentAccess.enabled) {
      sectionSyncActivated = true
    }
    const dropdownOpen = settingsDropdownOpen || modeSwitchDropdownOpen
    return (
      <div
        style={{ height: `${toolBarHeight}px` }}
        className={`liveView-toolbar ${isEmptyState ? ' liveView-toolbar--empty ' : ' liveView-toolbar--nonEmpty '}${this.props.nowPlayingMode === 'liveViewHybrid' ? ' liveView-toolbar--hybrid ' : ' '}${dropdownOpen ? ' liveView-toolbar--dropdownOpen ' : ''}`}
      >

        {!section &&
        <div className='liveView-toolbar-liveStatusIndicator liveView-toolbar-liveStatusIndicator--spacer' />}

        {section && (
        <React.Fragment>
          <div className='liveView-toolbar-liveStatusIndicator liveView-toolbar-liveStatusIndicator--live'>
            Live
            <SectionMarker type='colorOnly' label='Live' section={section} />
          </div>
          <div className={`liveView-toolbar-sectionNameContainer liveView-toolbar-sectionNameContainer--${nowPlayingMode}`}>
            <div className='liveView-toolbar-sectionName notranslate'>
              {section.name}
            </div>
            {sectionDataOwner && (
            <SectionDataOwnerLabel dataOwner={sectionDataOwner} />
            )}
          </div>
        </React.Fragment>
        )}

        {section && this.props.nowPlayingMode === 'liveViewHybrid' && (
        <div className='liveView-toolbar-hybridCountdownContainer'>

          {this.props.questionStage === 'scan' && (
          <div className='liveView-toolbar-hybridCountdownContainer-scanStopWatchContainer'>
            {!this.props.countdownEndsAt &&
            <SyncLiveViewToolbarScanStopwatch />}
          </div>
          )}

          <SyncLiveViewToolbarScanCountdown
            poll={this.props.poll}
            countdownEndsAt={this.props.countdownEndsAt}
            cancelCountdown={this.props.cancelCountdown}
            incrementCountdown={this.props.incrementCountdown}
            isScanning={this.props.questionStage === 'scan'}
            handleCountdownEnd={this.props.handleCountdownEnd}
            setCountdownSeconds={this.props.setCountdownSeconds}
            countDownTimerStartSecondsLength={this.props.countDownTimerStartSecondsLength}
          />
        </div>
        )}

        {/* NOW PLAYING SETTINGS - STUDENT AND DISPLAY LIST */}
        <LiveViewDropdown
          onDropdownOpen={(dropdown) => { this.onDropdownOpen(dropdown) }}
          // onDropdownOpen={()=>{this.setState({settingsDropdownOpen:true})}}
          onDropdownClose={() => { this.delayedOnDropdownClose('settings') }}
        />

        {/* SWITCH BETWEEN DIFFERENT NP MODES - CARDS/E-LEARNING/HYBRID */}
        {section && sectionSyncActivated && (
        <LiveViewModeSwitchDropdown
          section={section}
          switchNowPlayingMode={this.props.switchNowPlayingMode}
          nowPlayingMode={this.props.nowPlayingMode}
          onlineStudentsCount={this.props.onlineStudentsCount}
          onDropdownOpen={() => { this.onDropdownOpen('modeSwitch') }}
          onDropdownClose={() => { this.delayedOnDropdownClose('modeSwitch') }}
        />
        )}
        {!isEmptyState && (
        <button data-tooltip='Minimize' onClick={() => { minimiseLiveView() }} className='liveView-toolbar-btn liveView-toolbar-btn--minimize tooltip tooltip--label'>
          <Icon name='minimize' />
        </button>
        )}
        <button data-tooltip='Close' onClick={() => { stopLiveView() }} className='liveView-toolbar-btn liveView-toolbar-btn--close tooltip tooltip--label'>
          <Icon name='close' />
        </button>

      </div>
    )
  }
}

export default LiveViewToolBar
