import React from 'react'

function PageReloadModal(props) {
  const {
    actionWord,
    message,
    dismissModal,
    reloadPage,
  } = props

  return (
    <div className='pageReloadNotification'>
      {actionWord && (
      <span className='pageReloadNotification-actionWord'>
        {actionWord}
        {' '}
      </span>
      ) }
      <div className='pageReloadNotification-message'>
        {message}
      </div>
      <div className='pageReloadNotification-buttonContainer'>
        <button className='pageReloadNotification-button pageReloadNotification-button-dismiss' onClick={dismissModal}>
          Dismiss
        </button>
        <button className='pageReloadNotification-button pageReloadNotification-button-reload' onClick={reloadPage}>
          Reload Page
        </button>
      </div>
    </div>
  )
}

export default PageReloadModal
