import React from 'react'
import Icon from './Icons'
import EditorIcon from './EditorIcons'
import { revisitConsent } from '../../utils/cookieHelpers'

function EditorChoiceVideoYoutubeNotification(props) {
  const {
    designModifierClassName,
    handleRevisitConsent,
    closeButtonAction,
    UIInverseScale,
    showDarkTopControls,
  } = props

  return (
    <div className={`youtubeBlocked-container ${designModifierClassName || ''}`}>
      <div style={{ transform: `scale(${UIInverseScale})` }} className='slide-choice-media--video-topRight'>
        <button onClick={closeButtonAction} className={`slide-choice-media-smallBtn slide-choice-media--video-close${showDarkTopControls ? ' slide-choice-media-smallBtn--dark' : ''}`}>
          <EditorIcon name='close' />
        </button>
      </div>
      <div className={`youtubeBlocked-content ${designModifierClassName || ''}`}>
        <div className={`youtubeBlocked-icons ${designModifierClassName || ''}`}>
          <Icon name='video-disabled' />
        </div>
        <div className={`youtubeBlocked-text youtubeBlocked-text-simple ${designModifierClassName || ''}`}>
          <p>
            YouTube cookies disabled
            <br />
            <button type='button' className='youtubeBlocked-button-link' onClick={handleRevisitConsent}>manage settings</button>
          </p>
        </div>
      </div>
    </div>
  )
}

function SimpleYoutubeNotification(props) {
  const {
    designModifierClassName,
    handleRevisitConsent,
  } = props

  return (
    <div className={`youtubeBlocked-container ${designModifierClassName || ''}`}>
      <div className={`youtubeBlocked-content ${designModifierClassName || ''}`}>
        <div className={`youtubeBlocked-icons ${designModifierClassName || ''}`}>
          <Icon name='video-disabled' />
        </div>
        <div className={`youtubeBlocked-text youtubeBlocked-text-simple ${designModifierClassName || ''}`}>
          <p>
            YouTube cookies disabled
            <br />
            <button type='button' className='youtubeBlocked-button-link' onClick={handleRevisitConsent}>manage settings</button>
          </p>
        </div>
      </div>
    </div>
  )
}

function IconOnlyYoutubeNotification(props) {
  const {
    designModifierClassName,
    handleRevisitConsent,
  } = props

  const tooltipText = 'Enable YouTube Cookies'

  return (
    <div className={`youtubeBlocked-container ${designModifierClassName || ''}`}>
      <div className={`youtubeBlocked-content ${designModifierClassName || ''}`}>
        <div className={`youtubeBlocked-icons fullSizeIcon ${designModifierClassName || ''}`}>
          <div onClick={handleRevisitConsent} data-tooltip={tooltipText} className='tooltip tooltip--above'>
            <Icon name='video-disabled' />
          </div>
        </div>
      </div>
    </div>
  )
}

export function YoutubeBlocked(props) {
  const {
    designModifierClassName,
    notificationType,
    slideScale,
    handleCloseButton,
    UIInverseScale,
    showDarkTopControls,
  } = props

  const nonScalableNotificationTypes = ['editorChoice']

  const handleRevisitConsent = (event) => {
    event.preventDefault()
    revisitConsent()
  }

  let notificationTypeToUse = notificationType

  if (slideScale && slideScale < 0.5 && !nonScalableNotificationTypes.includes(notificationType)) {
    notificationTypeToUse = 'iconOnly'
  }

  switch (notificationTypeToUse) {
    case ('iconOnly'):
      return (
        <IconOnlyYoutubeNotification
          designModifierClassName={designModifierClassName}
          handleRevisitConsent={handleRevisitConsent}
        />
      )
    case ('editorChoice'):
      return (
        <EditorChoiceVideoYoutubeNotification
          designModifierClassName={designModifierClassName}
          handleRevisitConsent={handleRevisitConsent}
          closeButtonAction={handleCloseButton}
          UIInverseScale={UIInverseScale}
          showDarkTopControls={showDarkTopControls}
        />
      )
    default:
      return (
        <SimpleYoutubeNotification
          designModifierClassName={designModifierClassName}
          handleRevisitConsent={handleRevisitConsent}
        />
      )
  }
}
