export default function (state = [], action) {
  switch (action.type) {
    case 'RELOAD_PAGE':
      return action.pageReloadConfig

    case 'PAGE_RELOADED':
      return {}

    case 'LOGOUT':
      return {}

    default:
      return state
  }
}
