import React from 'react'
import { Spring, animated } from 'react-spring/renderprops'
import canAutoPlay from 'can-autoplay'
import YTReady from '../../../youtubeReady'
import QuestionVideoControlBar from '../../richStaticSlide/media/video/QuestionVideoControlBar'
import { generateRandomId } from '../../../utils/generateRandomId'

const WINDOW_ZOOM_ITEM_INTERNAL_PADDING = 20

function calculateItemLayout(nowPlayingLayout, choiceIndex, choiceCount, videoWidth, videoHeight) {
  // VIEWPORT DIMENSIONS
  const { viewportWidth } = nowPlayingLayout
  const { viewportHeight } = nowPlayingLayout

  // applied to bottom left and right
  // height of choice letterblock at 1280 (120px), plus a bit of padding as we're only applying internal padding to bottom
  const unscaledHeightChoiceLettersPanel = 120
  // eslint-disable-next-line no-mixed-operators
  const windowZoomChoiceLettersTopPadding = (nowPlayingLayout.slideDimensions.width / 1280 * unscaledHeightChoiceLettersPanel)
  // START VIDEO CONTAINER
  // Find position of original on-slide video container
  const videoContainerZoomElement = document.getElementById(`choice${choiceIndex}VideoZoomable`)
  let rect
  if (videoContainerZoomElement) {
    rect = videoContainerZoomElement.getBoundingClientRect()
  }

  let containerStartZoomWidth = 0
  let containerStartZoomHeight = 0
  let containerStartZoomX = 0
  let containerStartZoomY = 0

  if (rect) {
    containerStartZoomWidth = rect.width
    containerStartZoomHeight = rect.height
    containerStartZoomX = rect.x
    containerStartZoomY = rect.y
  }

  const containerAspect = containerStartZoomWidth / containerStartZoomHeight
  // GLOBAL VIDEO SCALE
  // we scale down YT video so doesn't look silly when zoomed
  // the numbers below are matched approximately to prompt video
  // scalar varies by size of original video, so here approximated by choice count

  let globalVideoScaler = 0.275

  if (choiceCount === 3) {
    globalVideoScaler = 0.35
  }
  if (choiceCount === 2) {
    globalVideoScaler = 0.475
  }

  const inverseGlobalVideoScaler = 1 / globalVideoScaler
  // START VIDEO ITEM
  // find the video dimensions, based on instrinsic video size, that will fit into this container

  const intrinsicVideoWidth = videoWidth
  const intrinsicVideoHeight = videoHeight
  const videoAspect = intrinsicVideoWidth / intrinsicVideoHeight

  let videoStartZoomWidth
  let videoStartZoomHeight

  if (videoAspect > containerAspect) { // video is more landscape than media choice container
    videoStartZoomWidth = containerStartZoomWidth
    videoStartZoomHeight = videoStartZoomWidth / videoAspect
  }

  if (videoAspect < containerAspect) { // video is more portrait than media choice container
    videoStartZoomHeight = containerStartZoomHeight
    videoStartZoomWidth = videoStartZoomHeight * videoAspect
  }

  // Scaled because has to be bigger so title's don't look silly
  const scaledVideoStartZoomWidth = videoStartZoomWidth * inverseGlobalVideoScaler
  const scaledVideoStartZoomHeight = videoStartZoomHeight * inverseGlobalVideoScaler

  // Center the video within the container
  const videoStartZoomX = containerStartZoomX + ((containerStartZoomWidth - videoStartZoomWidth) / 2)
  const videoStartZoomY = containerStartZoomY + ((containerStartZoomHeight - videoStartZoomHeight) / 2)

  // END ZOOM ITEM DIMENSIONS

  // find maximum available width and height
  const videoEndZoomMaxWidth = viewportWidth - WINDOW_ZOOM_ITEM_INTERNAL_PADDING - WINDOW_ZOOM_ITEM_INTERNAL_PADDING
  const videoEndZoomMaxHeight = viewportHeight - WINDOW_ZOOM_ITEM_INTERNAL_PADDING - WINDOW_ZOOM_ITEM_INTERNAL_PADDING

  const videoEndZoomMaxAvailableAspect = videoEndZoomMaxWidth / videoEndZoomMaxHeight

  // find actual fitting item width and height
  let videoEndZoomWidth
  let videoEndZoomHeight

  if (videoEndZoomMaxAvailableAspect >= videoAspect) { // screen wider than item
    videoEndZoomHeight = videoEndZoomMaxHeight
    videoEndZoomWidth = videoEndZoomHeight * videoAspect
  }
  if (videoEndZoomMaxAvailableAspect < videoAspect) { // screen narrower than item
    videoEndZoomWidth = videoEndZoomMaxWidth
    videoEndZoomHeight = videoEndZoomWidth / videoAspect
  }

  // scaled using global scalar
  // const scaledVideoEndZoomWidth = videoEndZoomWidth * inverseGlobalVideoScaler
  // const scaledVideoEndZoomHeight = videoEndZoomHeight * inverseGlobalVideoScaler

  // find end-state X and Y, this is to viewport
  // basically center, for Y we also need to offset with the top padding
  const videoEndZoomX = (viewportWidth - videoEndZoomWidth) / 2
  const videoEndZoomY = Math.max(((viewportHeight - videoEndZoomHeight) / 2), windowZoomChoiceLettersTopPadding)

  //
  // ZOOM START --> ZOOM END

  // SCALE FACTOR
  const videoEndZoomScale = videoEndZoomWidth / videoStartZoomWidth

  // TRANSFORM ORIGIN (this effectively translates position of slide)
  const transformOriginX = (videoStartZoomX - videoEndZoomX) / (videoEndZoomWidth - videoStartZoomWidth)
  const transformOriginY = (videoStartZoomY - videoEndZoomY) / (videoEndZoomHeight - videoStartZoomHeight)

  //
  // STYLING IS FIXED
  // zooming also repositions slide through transform origin location

  const itemStyle = {
    width: `${videoStartZoomWidth}px`,
    height: `${videoStartZoomHeight}px`,
    left: `${videoStartZoomX}px`,
    top: `${videoStartZoomY}px`,
    transformOrigin: `${transformOriginX * 100}% ${transformOriginY * 100}%`,
  }

  const videoStyle = {
    width: `${scaledVideoStartZoomWidth}px`,
    height: `${scaledVideoStartZoomHeight}px`,
    transform: `scale(${globalVideoScaler}`,
  }

  const itemContainerStyle = {
    width: `${containerStartZoomWidth}px`,
    height: `${containerStartZoomHeight}px`,
    left: `${containerStartZoomX}px`,
    top: `${containerStartZoomY}px`,
  }

  //
  // CONTROL BAR POSITIONS

  // START ZOOM CONTROL BAR (i.e. on choice)

  const slideWidth = nowPlayingLayout.slideDimensions.width

  const slideScale = slideWidth / 1280 // controls should be the same size on any size screen
  const startZoomControlBarScaler = 1.2 // hardcoded value to make controls a nice size
  const scaleStartControlBar = startZoomControlBarScaler * slideScale // controls can be slightly larger than normal

  const initialControlBarHeight = 48

  const startZoomControlBarContainer = {
    transform: `scale(${scaleStartControlBar})`,
    width: `${containerStartZoomWidth / scaleStartControlBar}px`,
    height: `${initialControlBarHeight}px`,
    // go to bottom then subtract the height of the bar
  }

  // END ZOOM CONTROL BAR (i.e. on choice)

  const endZoomControlBarScaler = 1.6 // hardcoded value to make controls a nice size
  const scaleEndControlBar = endZoomControlBarScaler * slideScale // controls can be slightly larger than normal

  const endZoomControlBarContainer = {
    transform: `scale(${scaleEndControlBar})`,
    width: `${viewportWidth / scaleEndControlBar}px`,
    height: `${initialControlBarHeight}px`,
    bottom: `${viewportHeight * 0.04}px`, // hardcoded, should come off the floor a bit
    left: '0px',
  }

  return {
    itemStyle,
    videoEndZoomScale,
    itemContainerStyle,
    videoStyle,
    startZoomControlBarContainer,
    endZoomControlBarContainer,

  }
}

class LiveViewChoiceVideoPlayer extends React.Component {
  constructor() {
    super()
    this.loadVideo = this.loadVideo.bind(this)
    this.playVideo = this.playVideo.bind(this)
    this.stopVideo = this.stopVideo.bind(this)
    this.pauseVideo = this.pauseVideo.bind(this)
    this.onPlayerReady = this.onPlayerReady.bind(this)
    this.onStateChange = this.onStateChange.bind(this)
    this.toggleMuteVideo = this.toggleMuteVideo.bind(this)
    this.updatePlayerCurrentTime = this.updatePlayerCurrentTime.bind(this)
    this.startTimer = this.startTimer.bind(this)
    this.stopTimer = this.stopTimer.bind(this)
    this.seekTo = this.seekTo.bind(this)
    this.onClipFinished = this.onClipFinished.bind(this)
    this.onVideoEnd = this.onVideoEnd.bind(this)
    this.restartVideo = this.restartVideo.bind(this)
    this.updateControlPlayback = this.updateControlPlayback.bind(this)
    this.skipPlayerCurrentTime = this.skipPlayerCurrentTime.bind(this)
    this.calculateLayout = this.calculateLayout.bind(this)
    this.zoomAnimationFinished = this.zoomAnimationFinished.bind(this)

    this.playerId = generateRandomId()

    this.state = {
      playerReady: false,
      isPlaying: false,
      isMuted: false,
      playerCurrentTime: 0,
      isBuffering: false,
      carouselIsOpen: false,
      itemStyle: {},
      videoEndZoomScale: 0,
      itemContainerStyle: {},
      videoStyle: {},
      startZoomControlBarContainer: {},
      endZoomControlBarContainer: {},
    }
    this.timer = null
  }

  componentDidMount() {
    this.calculateLayout()
    const youtubeReady = YTReady
    youtubeReady.then((YT) => {
      this.loadVideo(YT)
    })
  }

  componentDidUpdate(prevProps) {
    if ((this.props.layoutKey !== prevProps.layoutKey) || (this.props.showStudentList !== prevProps.showStudentList)) {
      this.calculateLayout()
    }
  }

  componentWillUnmount() {
    if (this.state.playerReady) {
      this.stopTimer()
      this.stopVideo()
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.playbackItem === nextProps.itemName && nextProps.pageVisibilityState === 'visible' && nextProps.nowPlayingModalVisible === true && nextProps.playbackItem === nextProps.itemName && this.props.clientPlaybackCommand === null && nextProps.clientPlaybackCommand) {
      this.props.clearPlaybackCommand()
      if (nextProps.clientPlaybackCommand.command === 'skip forward') {
        this.skipPlayerCurrentTime('forwards', nextProps.clientPlaybackCommand.length / 1000)
      } else {
        this.skipPlayerCurrentTime('backwards', nextProps.clientPlaybackCommand.length / 1000)
      }
    }

    if (nextProps.playbackItem === nextProps.itemName && this.props.pageVisibilityState === 'visible' && nextProps.nowPlayingModalVisible === true) {
      if (nextProps.isPlaying) {
        if (!this.state.isPlaying) {
          this.playVideo()
        }
      } else if (this.state.isPlaying) {
        this.pauseVideo()
      }
    }

    if (nextProps.playbackItem !== nextProps.itemName) {
      if (this.state.isPlaying) {
        this.stopVideo()
      }
    }

    if (this.props.pageVisibilityState === 'visible' && nextProps.pageVisibilityState === 'hidden') {
      if (this.state.isPlaying) {
        this.pauseVideo()
      }
    }
  }

  calculateLayout() {
    const itemLayout = calculateItemLayout(this.props.nowPlayingLayout, this.props.choiceIndex, this.props.choiceCount, this.props.videoWidth, this.props.videoHeight)
    this.setState({
      itemStyle: itemLayout.itemStyle,
      videoEndZoomScale: itemLayout.videoEndZoomScale,
      itemContainerStyle: itemLayout.itemContainerStyle,
      videoStyle: itemLayout.videoStyle,
      startZoomControlBarContainer: itemLayout.startZoomControlBarContainer,
      endZoomControlBarContainer: itemLayout.endZoomControlBarContainer,
    })
  }

  skipPlayerCurrentTime(skipDirection, skipAmount) {
    if (this.state.playerReady) {
      const currentTime = this.player.getCurrentTime()
      let newCurrentTime = currentTime
      if (skipDirection === 'forwards') {
        newCurrentTime = Math.min(newCurrentTime + skipAmount, this.props.end)
      } else {
        newCurrentTime = Math.max(newCurrentTime - skipAmount, this.props.start)
      }
      this.seekTo(newCurrentTime)
    }
  }

  zoomAnimationFinished() {
    if (!this.props.choiceMediaZoomed) {
      this.setState({ carouselIsOpen: false })
    }
    if (this.props.choiceMediaZoomed) {
      this.setState({ carouselIsOpen: true })
    }
  }

  loadVideo(YT) {
    const { video } = this.props
    this.player = new YT.Player(`youtube-player-${this.playerId}`, {
      videoId: video.youtubeId,
      playerVars: {
        controls: 0, // disable controls
        // 'cc_load_policy': 1, // don't know what this does
        loop: 0, // loop video
        autoplay: 0, // autoplay
        fs: 0, // show full screen option
        disablekb: 1, // disable keyboard shortcuts
        rel: 0, // either hides related (depreciated) or only shows from same chanel
        // eslint-disable-next-line camelcase
        iv_load_policy: 3, // don't show video annotations by default
        hl: 'en', // interface language
        // eslint-disable-next-line camelcase
        cc_lang_pref: 'en',
        // don't know what these do, from EdPuzzle
        playsInline: 1,
        showinfo: 0, // undocumented, should hide title but I guess depreciated
        wmode: 'opaque', // undocumented
        start: (this.props.start),
        end: (this.props.end + 1),
      },
      host: 'http://www.youtube-nocookie.com',
      events: {
        onReady: this.onPlayerReady,
        onStateChange: this.onStateChange,
      },
    })
  }

  onPlayerReady() {
    const muted = this.player.isMuted()
    this.setState({ playerReady: true, isMuted: muted })
  }

  onStateChange(event) {
    switch (event.data) {
      case -1:// ended
        this.setState({ isPlaying: false })
        this.stopTimer()
        // this.updateControlPlayback(false)
        this.setState({ isBuffering: false })
        break
      case 0:// ended
        this.setState({ isPlaying: false })
        this.stopTimer()
        // this.updateControlPlayback(false)
        this.onVideoEnd()
        this.setState({ isBuffering: false })
        break
      case 1:// playing
        this.setState({ isPlaying: true })
        this.startTimer()
        this.updateControlPlayback(true)
        this.setState({ isBuffering: false })
        this.props.hideAutoplayFailedAlert()
        break
      case 2:// paused
        this.stopTimer()
        this.setState({ isPlaying: false })
        this.updateControlPlayback(false)
        this.setState({ isBuffering: false })
        break
      case 3:// BUFFERING
        this.stopTimer()
        this.setState({ isBuffering: true })
        break
      default:
        break
    }
  }

  updateControlPlayback(isPlaying) {
    this.props.updateControlPlayback(this.props.itemName, isPlaying)
  }

  stopVideo() {
    this.player.stopVideo()
  }

  playVideo() {
    setTimeout(() => {
      const playerState = this.player.getPlayerState()
      let ytAutoplay
      if (playerState === 1 || playerState === 3) {
        ytAutoplay = true
      } else {
        ytAutoplay = false
      }
      let canAutoplayCheck
      canAutoPlay.video().then(({ result }) => {
        if (result === true) {
          canAutoplayCheck = true
        } else {
          canAutoplayCheck = false
        }
        if (ytAutoplay === false && canAutoplayCheck === false) {
          this.props.showAutoplayFailedAlert()
          this.updateControlPlayback(false)
          if (window.analytics) {
            window.analytics.track('Show autoplay failed alert', {
              mediaType: 'video',
              playbackItem: this.props.itemName,
            })
          }
        } else {
          this.props.hideAutoplayFailedAlert()
        }
      })
    }, 1000)
    if (this.state.playerReady) {
      const playerState = this.player.getPlayerState()
      if (playerState === 0) {
        this.seekTo(this.props.start)
        this.setState({ playerCurrentTime: this.props.start })
      }
      this.setState({ isPlaying: true })
      this.player.playVideo()
    }
  }

  pauseVideo() {
    if (this.state.playerReady) {
      this.setState({ isPlaying: false })
      this.player.pauseVideo()
    }
  }

  startTimer() {
    this.timer = setInterval(() => {
      this.updatePlayerCurrentTime()
    }, 50)
  }

  stopTimer() {
    if (this.timer) {
      clearInterval(this.timer)
    }
  }

  updatePlayerCurrentTime() {
    if (!this.state.isBuffering && this.state.playerReady) {
      const currentTime = this.player.getCurrentTime()
      if (currentTime > this.state.playerCurrentTime) {
        if (currentTime < this.props.end) {
          this.setState({ playerCurrentTime: this.player.getCurrentTime() })
        } else {
          this.onClipFinished()
        }
      }
    }
  }

  onClipFinished() {
    this.player.pauseVideo()
    this.seekTo(this.props.start)
    this.setState({ isPlaying: false, playerCurrentTime: this.props.start })
  }

  onVideoEnd() {
    this.player.pauseVideo()
    this.seekTo(this.props.start)
    this.setState({ playerCurrentTime: this.props.start })
  }

  seekTo(seconds) {
    if (this.state.playerReady) {
      this.setState({ isBuffering: true, playerCurrentTime: seconds })
      this.player.seekTo(seconds, true)
    }
  }

  restartVideo() {
    if (this.state.playerReady) {
      this.setState({ isBuffering: true, playerCurrentTime: this.props.start })
      this.player.seekTo(this.props.start)
    }
  }

  toggleMuteVideo() {
    const { player } = this
    if (player.isMuted()) {
      player.unMute()
    } else {
      player.mute()
    }
    this.setState({ isMuted: !player.isMuted() })
  }

  render() {
    const {
      nowPlayingLayout,
      choiceMediaZoomed,
      choiceIndex,
      animationDisabled,
      activeZoomedChoiceIndex,
      isLastZoomedItem,
      isYoutubeAllowed,
    } = this.props
    const {
      itemStyle, videoEndZoomScale, itemContainerStyle, videoStyle, startZoomControlBarContainer, endZoomControlBarContainer, carouselIsOpen,
    } = this.state

    const showEndZoomControlBar = !this.state.isPlaying // maybe later fade out after paused for a while

    const { viewportWidth } = nowPlayingLayout

    // CAROUSEL OFFSET
    const pageWidth = viewportWidth
    const indexDifferent = choiceIndex - activeZoomedChoiceIndex
    const isActiveZoomedChoice = choiceIndex === activeZoomedChoiceIndex

    const carouselOffset = pageWidth * indexDifferent

    const zoomMediaScaledTranslateXCarousel = carouselOffset / videoEndZoomScale

    let setDurationTo0 = false
    if (!carouselIsOpen && !isActiveZoomedChoice) {
      setDurationTo0 = true
    }
    if (carouselIsOpen && !choiceMediaZoomed && !isActiveZoomedChoice) {
      setDurationTo0 = true
    }

    const setDelay = !carouselIsOpen && !isActiveZoomedChoice

    // Z-INDEX MANAGEMENT
    const moveItemBelowDarkBG = (!choiceMediaZoomed && !isLastZoomedItem) || (!carouselIsOpen && !isLastZoomedItem)

    // FADE OUT IF OTHER CHOICE PLAYING
    const otherChoicePlaying = this.props.choicesPlaying && !this.props.isPlayingChoice && !choiceMediaZoomed

    const usedItemContainerStyle = {
      ...itemContainerStyle,
      display: isYoutubeAllowed ? 'block' : 'none',
    }

    return (
      <React.Fragment>
        <div style={usedItemContainerStyle} className={`liveView-mediaItemContainer liveView-mediaItemContainer--choiceVideoContainer ${isLastZoomedItem ? ' liveView-mediaItem--isLastZoomedItem ' : ''}${moveItemBelowDarkBG ? ' liveView-mediaItem--moveItemBelowDarkBG ' : ''}${otherChoicePlaying ? ' liveView-mediaItem--choiceSoundPlayer--otherChoicesPlaying ' : ''}`}>
          <Spring
            onRest={this.zoomAnimationFinished}
            native
            config={{
              duration: setDurationTo0 ? 0 : undefined,
              delay: setDelay ? 350 : undefined,
            }}
            from={{ transform: 'scale(1) translateX(0px)' }}
            to={{ transform: (choiceMediaZoomed && videoEndZoomScale) ? `scale(${videoEndZoomScale}) translateX(${zoomMediaScaledTranslateXCarousel})` : 'scale(1) translateX(0px)' }}
            immediate={animationDisabled}
          >
            {(props) => (
              <animated.div onClick={this.props.toggleZoomMedia} key={choiceIndex} style={{ ...itemStyle, ...props }} className={`liveView-mediaItem liveView-mediaItem--choiceVideo ${isLastZoomedItem ? ' liveView-mediaItem--isLastZoomedItem ' : ''}${moveItemBelowDarkBG ? ' liveView-mediaItem--moveItemBelowDarkBG ' : ''}`}>
                <div
                  className='liveView-mediaItem--choiceVideo-videoContainer'
                  style={{
                    position: 'absolute', top: '0px', left: '0px', ...videoStyle,
                  }}
                >
                  <div
                    style={{
                      position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%',
                    }}
                    id={`youtube-player-${this.playerId}`}
                  />
                </div>

              </animated.div>
            )}
          </Spring>

          {!choiceMediaZoomed && (
          <div style={startZoomControlBarContainer} className='liveView-mediaItem--choiceVideo-startZoomControlBarContainer'>
            {this.state.playerReady && (
            <QuestionVideoControlBar
              playVideo={() => { this.player.playVideo() }}
              pauseVideo={() => { this.player.pauseVideo() }}
              YTPlayer={this.player}
              clipStartTime={this.props.start}
              clipEndTime={this.props.end}
              duration={this.props.end - this.props.start}
              isPlaying={this.state.isPlaying}
              isMuted={this.state.isMuted}
              toggleMuteVideo={this.toggleMuteVideo}
              playerCurrentTime={this.state.playerCurrentTime}
              seekTo={this.seekTo}
              zoomable
              zoomMedia={() => { this.props.zoomMedia(this.props.itemName) }}
              restartVideo={this.restartVideo}
              liveViewChoiceStartZoom
              mediaZoomed={choiceMediaZoomed}
              hideMuteButton
            />
            )}
          </div>
          )}

          {choiceMediaZoomed && isActiveZoomedChoice && (
          <div style={endZoomControlBarContainer} className={`liveView-mediaItem--choiceVideo-endZoomControlBarContainer ${showEndZoomControlBar ? ' liveView-mediaItem--choiceVideoPlayer-endZoomControlBarContainer--show' : ' liveView-mediaItem--choiceVideoPlayer-endZoomControlBarContainer--hide'}`}>
            {this.state.playerReady && (
            <QuestionVideoControlBar
              playVideo={() => { this.player.playVideo() }}
              pauseVideo={() => { this.player.pauseVideo() }}
              YTPlayer={this.player}
              clipStartTime={this.props.start}
              clipEndTime={this.props.end}
              duration={this.props.end - this.props.start}
              isPlaying={this.state.isPlaying}
              isMuted={this.state.isMuted}
              toggleMuteVideo={this.toggleMuteVideo}
              playerCurrentTime={this.state.playerCurrentTime}
              seekTo={this.seekTo}
              zoomable
              zoomMedia={() => { this.props.zoomMedia(this.props.itemName) }}
              restartVideo={this.restartVideo}
              mediaZoomed={choiceMediaZoomed}
              hideMuteButton
              unzoomMedia={this.props.unzoomMedia}
            />
            )}
          </div>
          )}
        </div>
      </React.Fragment>
    )
  }
}

export default LiveViewChoiceVideoPlayer
