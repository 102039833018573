import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import debounce from 'lodash/debounce'
import find from 'lodash/find'
import findIndex from 'lodash/findIndex'
import throttle from 'lodash/throttle'
import DocumentTitle from 'react-document-title'
import { hideMenu } from 'react-contextmenu/modules/actions'
import { undo, redo } from 'prosemirror-history'
import { updateUserConfig } from '../../actions/userConfig'
import { addQuestionImage } from '../../prosemirror/utils/editorActions/addImage'
import { createNewSet, updateSetContent, updateSetMeta } from '../../actions/sets'
import { createNewQuestion, updateQuestionContent, updateQuestionMeta } from '../../actions/questions'
import {
  showDuplicateAndEditModal, showConfirmModal, hideModal, showRepoSelectionModal,
} from '../../actions/modals'
import { showNotification } from '../../actions/notifications'
import RichEditorPage from '../../components/richEditor/RichEditorPage'
import { convertPMNodeToQuestionObject } from '../../prosemirror/utils/convertPMNodeToQuestionObject'
import { handleEditorTabKeypress } from '../../prosemirror/utils/handleEditorTabKeypress'
import { setActiveQuestion } from '../../prosemirror/utils/editorActions/setActiveQuestion'
import { reorderSetQuestions } from '../../prosemirror/utils/reorderSetQuestions'
import {
  fetchLocksForRepo,
  updateQuestionLock,
  deleteLockForQuestion,
  synchronousDeleteLockForQuestion,
  updateSetLock,
  deleteLockForSet,
  synchronousDeleteLockForSet,
} from '../../actions/locks'
import { updateQuestionFontSizes } from '../../prosemirror/utils/editorActions/updateQuestionFontSizes'
import { updateQuestionMediaDynamicHeight } from '../../prosemirror/utils/editorActions/updateQuestionMediaDynamicHeight'
import richSetTemplate from '../../utils/editors/richSetTemplate'
import richQuestionTemplate from '../../utils/editors/richQuestionTemplate'
import FontSizeCalculatorComponent from '../../components/fontSizeCalculator/FontSizeCalculatorComponent'
import { uploadNewImageToCloudinary } from '../../actions/cloudinary'
import { makeACopySet, makeACopyQuestion } from '../../utils/editors/makeACopy'
import { addQuestionToSet } from '../../prosemirror/utils/editorActions/addQuestionToSet'
import { duplicateActiveQuestion } from '../../prosemirror/utils/editorActions/duplicateActiveQuestion'
import { deleteActiveQuestion } from '../../prosemirror/utils/editorActions/deleteActiveQuestion'
import { copyQuestion } from '../../utils/copyPaste'
import ImportQuestionsContainer from '../importQuestions/ImportQuestionsContainer'
import { repoPusherSetup } from '../../utils/pusher/repoPusherSetup'
import { setChoiceToCorrect } from '../../prosemirror/utils/editorActions/setChoiceToCorrect'
import { convertQuestionIntoSet } from '../../utils/editors/convertQuestionIntoSet'
import { newQuestion } from '../../utils/newItem'

function editorIsFocused() {
  let editorIsFocused = false
  if (window.view) {
    editorIsFocused = window.view.hasFocus()
  }
  return editorIsFocused
}

function getQuestionOrderArray() {
  let found
  let questionOrderArray
  window.view.state.doc.descendants((node) => {
    if (node.attrs.questionOrderArray) {
      questionOrderArray = node.attrs.questionOrderArray
    }
    if (found) return false
  })
  return questionOrderArray
}

function slideListSlideIsFocused() {
  let focused = false
  const activeElementId = document.activeElement.id
  if (activeElementId && activeElementId.includes('draggableSlide')) {
    focused = true
  }
  return focused
}

const DEBOUNCE_TIME = 1200
const LOCK_THROTTLE_TIME = 1000 * 10

class RichEditorContainer extends Component {
  constructor(props) {
    super(props)
    this.onDocUpdate = this.onDocUpdate.bind(this)
    this.lockItem = this.lockItem.bind(this)
    this.saveChanges = this.saveChanges.bind(this)
    this.createNewSet = this.createNewSet.bind(this)
    this.createNewQuestion = this.createNewQuestion.bind(this)
    this.updateSet = this.updateSet.bind(this)
    this.setActiveQuestion = this.setActiveQuestion.bind(this)
    this.updateQuestionFontSizes = this.updateQuestionFontSizes.bind(this)
    this.updateQuestionMediaDynamicHeight = this.updateQuestionMediaDynamicHeight.bind(this)
    this.updateSetTitle = this.updateSetTitle.bind(this)
    this.updateQuestionOrder = this.updateQuestionOrder.bind(this)
    this.beforeUnload = this.beforeUnload.bind(this)
    this.shouldDeleteLock = this.shouldDeleteLock.bind(this)
    this.notThrottledDocUpdate = this.notThrottledDocUpdate.bind(this)
    this.onAddQuestionToSet = this.onAddQuestionToSet.bind(this)
    this.onDeleteActiveQuestion = this.onDeleteActiveQuestion.bind(this)
    this.handleSlideListContextMenuClick = this.handleSlideListContextMenuClick.bind(this)
    this.moveSlideUp = this.moveSlideUp.bind(this)
    this.moveSlideDown = this.moveSlideDown.bind(this)
    this.cutQuestion = this.cutQuestion.bind(this)
    this.reloadEditor = this.reloadEditor.bind(this)
    this.handleSlideListKeyDown = this.handleSlideListKeyDown.bind(this)
    this.incrementActiveQuestionIndex = this.incrementActiveQuestionIndex.bind(this)
    this.focusActiveQuestionInSlideList = this.focusActiveQuestionInSlideList.bind(this)
    this.handleKeyDown = this.handleKeyDown.bind(this)
    this.showArchiveSetModal = this.showArchiveSetModal.bind(this)
    this.archiveSet = this.archiveSet.bind(this)
    this.showArchiveQuestionModal = this.showArchiveQuestionModal.bind(this)
    this.archiveQuestion = this.archiveQuestion.bind(this)
    this.scrollActiveSlideIntoView = this.scrollActiveSlideIntoView.bind(this)
    this.convertQuestionIntoSet = this.convertQuestionIntoSet.bind(this)
    this.newQuestionEditorTab = this.newQuestionEditorTab.bind(this)
    this.copyQuestionToClipboard = this.copyQuestionToClipboard.bind(this)
    this.toggleDisableFastFractionInputRule = this.toggleDisableFastFractionInputRule.bind(this)
    this.saveChanges = debounce(this.saveChanges, DEBOUNCE_TIME, { leading: false })
    this.onDocUpdate = throttle(this.onDocUpdate, 1000)
    this.lockItem = throttle(this.lockItem, LOCK_THROTTLE_TIME, { edge: 'both' })

    let currentSet = null
    let currentQuestion = null
    let activeQuestion = null
    let parentFolder = null// We use the parentFolder for creating sets within folders
    let repo = null
    if (typeof window.parentFolder === 'string') { // Prevent invalid folder value
      parentFolder = window.parentFolder
    }
    if (typeof window.repo === 'string') { // Prevent invalid folder value
      repo = window.repo
    }
    let isSetEditor = false
    if (window.location.pathname.indexOf('/seteditor/') > -1) {
      isSetEditor = true
    }
    if (isSetEditor) {
      if (props.match.params.id === 'newSet') {
        currentSet = richSetTemplate()
        activeQuestion = currentSet.questions[0]
        currentSet.folder = parentFolder
        currentSet.repo = repo
      } else if (props.currentSet) {
        currentSet = props.currentSet
        activeQuestion = props.currentSet.questions[0]
      }
    } else if (props.match.params.id === 'newquestion') {
      currentQuestion = richQuestionTemplate(true)
      activeQuestion = currentQuestion
      currentQuestion.folder = parentFolder
      currentQuestion.repo = repo
    } else if (props.currentQuestion) {
      currentQuestion = props.currentQuestion
      activeQuestion = props.currentQuestion
    }

    this.newSetPromises = [] // for additional edits while new set is being created (could clean up and combine set/questions)
    this.newQuestionPromises = []

    this.state = {
      isSetEditor,
      currentQuestion,
      currentSet,
      activeQuestion,
      isSaving: false,
      unableToSave: false,
      doc: null,
      userLockedSet: false,
      activeQuestionId: activeQuestion ? activeQuestion.questionId : null,
      perfectCombo: {},
      questionMediaDynamicHeight: null,
      userHasMadeEdit: false,
      showImportQuestionsModal: false,
      richEditorKey: 0, // to force editor reload e.g. after import questions
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.props.parentRepo && nextProps.parentRepo && nextProps.parentRepo.shared) {
      repoPusherSetup(nextProps.parentRepo.id)
    }
    if (this.state.currentSet === null && nextProps.currentSet) {
      if (nextProps.currentSet.repo) {
        this.props.fetchLocksForRepo(nextProps.currentSet.repo)
      }
      const activeQuestionIndex = 0
      this.setState({
        currentSet: nextProps.currentSet,
        activeQuestion: nextProps.currentSet.questions[activeQuestionIndex],
        activeQuestionId: nextProps.currentSet.questions[activeQuestionIndex].questionId,
      })
    }
    if (this.state.currentQuestion === null && nextProps.currentQuestion) {
      if (nextProps.currentQuestion.repo) { // TODO this bit
        this.props.fetchLocksForRepo(nextProps.currentQuestion.repo)
      }
      this.setState({
        currentQuestion: nextProps.currentQuestion,
        activeQuestion: nextProps.currentQuestion,
      })
    }
    if (this.props.itemIsLockedByAnotherUser && this.props.currentSet !== nextProps.currentSet) {
      let newActiveQuestionIndex = 0
      if (this.state.activeQuestion) {
        newActiveQuestionIndex = findIndex(nextProps.currentSet.questions, (setQuestion) => setQuestion.questionId === this.state.activeQuestion.questionId)
      }
      this.setState({
        currentSet: nextProps.currentSet,
        activeQuestion: nextProps.currentSet.questions[Math.max(newActiveQuestionIndex, 0)],
      })
    }
    if (this.props.itemIsLockedByAnotherUser && !nextProps.itemIsLockedByAnotherUser) {
      this.reloadEditor()
    }
    if (this.props.questionsInSetLimit && !nextProps.questionsInSetLimit) { // reload editor if user has upgraded
      this.reloadEditor()
    }
  }

  componentDidMount() {
    window.addEventListener('beforeunload', this.beforeUnload)
    document.addEventListener('keydown', this.handleKeyDown)
    if (window.analytics) {
      let testGroup = null
      const setLimitTestGroup = JSON.parse(localStorage.getItem('meta.setGroup'))
      if (setLimitTestGroup) {
        testGroup = setLimitTestGroup
      }
      let itemType = 'Question'
      if (this.state.isSetEditor) {
        itemType = 'Set'
      }
      let loadState = 'Edit'
      if (this.props.match.params.id === 'newSet' || this.props.match.params.id === 'newquestion') {
        loadState = 'New'
      }
      window.analytics.page('Item Editor', {
        isRichEditor: 'true',
        itemType,
        loadState: `${loadState}`,
        windowDimensions: `${window.innerWidth} x ${window.innerHeight}`,
        // eslint-disable-next-line camelcase
        test_group_set_limit: testGroup,
      })
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown)
    window.removeEventListener('beforeunload', this.beforeUnload)
    const shouldDeleteLock = this.shouldDeleteLock()
    if (shouldDeleteLock) {
      if (this.state.isSetEditor) {
        this.props.deleteLockForSet(this.props.match.params.id)
        this.lockItem.cancel()
      } else {
        this.props.deleteLockForQuestion(this.props.match.params.id)
        this.lockItem.cancel()
      }
    }
  }

  beforeUnload() {
    const shouldDeleteLock = this.shouldDeleteLock()
    if (shouldDeleteLock) {
      if (this.state.isSetEditor) {
        this.props.deleteLockForSet(this.props.match.params.id)
      } else {
        this.props.deleteLockForQuestion(this.props.match.params.id)
      }
    }
  }

  shouldDeleteLock() {
    if (this.state.userLockedSet) {
      return true
    }
    if (this.props.lock && this.state.currentSet && this.state.currentSet.repo && !this.props.itemIsLockedByAnotherUser) {
      return true
    } if (this.props.lock && this.state.currentQuestion && this.state.currentQuestion.repo && !this.props.itemIsLockedByAnotherUser) {
      return true
    }
    return false
  }

  lockItem() {
    if (this.state.currentSet && this.state.currentSet.id && this.state.currentSet.repo) {
      this.setState({ userLockedSet: true })
      this.props.updateSetLock(this.props.match.params.id).then(() => {})
    } else if (this.state.currentQuestion && this.state.currentQuestion.repo) {
      this.setState({ userLockedSet: true })
      this.props.updateQuestionLock(this.props.match.params.id).then(() => {})
    }
  }

  reloadEditor() { // for after import questions or change in locked status
    if (this.state.isSetEditor) {
      let activeQuestionIndex = 0
      const { currentSet } = this.props
      if (currentSet.repo) {
        this.lockItem()
      }
      currentSet.questions.forEach((question, i) => {
        if (question.source) {
          const questionNode = JSON.parse(question.source)
          if (questionNode.attrs.activeslide) {
            activeQuestionIndex = i
          }
        }
      })
      const { richEditorKey } = this.state
      this.setState({
        currentSet,
        activeQuestion: currentSet.questions[activeQuestionIndex],
        activeQuestionId: currentSet.questions[activeQuestionIndex].questionId,
        richEditorKey: (richEditorKey + 1),
      })
    } else {
      const { currentQuestion } = this.props
      if (currentQuestion.repo) {
        this.lockItem()
      }
      const { richEditorKey } = this.state
      this.setState({
        currentQuestion,
        activeQuestion: currentQuestion,
        richEditorKey: (richEditorKey + 1),
      })
    }
  }

  toggleDisableFastFractionInputRule() {
    const newDisableFastFractionInputRule = !this.props.disableFastFractionInputRule
    if (this.props.user) {
      const userId = this.props.user.id
      const requestData = { disableFastFractionInputRule: newDisableFastFractionInputRule }
      this.props.updateUserConfig(requestData, userId)
      if (newDisableFastFractionInputRule) {
        this.props.showNotification('You can re-enable Auto Fractions at any time from the top-left menu.', 'Auto Fractions disabled: ', 'default')
      } else {
        this.props.showNotification('Create a simple number fraction just by typing 2/3 and hitting spacebar.', 'Auto Fractions enabled: ', 'create')
      }
    }
    if (window.analytics) {
      window.analytics.track('Toggle Disable Fast Fraction Input Rule', {
        toggleOn: !newDisableFastFractionInputRule,
      })
    }
  }

  updateSetTitle(newTitle) {
    const { currentSet } = this.state
    this.lockItem()
    this.setState({ currentSet: { ...currentSet, name: newTitle } })
    this.saveChanges()
  }

  saveChanges() {
    if (this.props.isViewOnly === false && !this.props.itemIsLockedByAnotherUser) {
      if (this.state.isSetEditor) {
        const { currentSet } = this.state
        if (currentSet.id && currentSet.repo) {
          this.lockItem()
        }
        const nowDate = new Date()
        currentSet.clientModified = nowDate
        if (!currentSet.name) {
          currentSet.name = 'Untitled Set'
        }
        if (!currentSet.id) {
          if (this.newSetPromises.length === 0) { // only create the set once
            this.newSetPromises.push(this.createNewSet(currentSet))
          } else { // if you make additional changes after you have started creating the set then wait until it is created to update it
            Promise.all(this.newSetPromises).then(() => {
              this.updateSet(currentSet)
            })
          }
        } else {
          this.updateSet(currentSet)
        }
      } else { // questionEditor
        const { currentQuestion } = this.state
        if (currentQuestion.id && currentQuestion.repo) {
          this.lockItem()
        }
        const nowDate = new Date()
        currentQuestion.clientModified = nowDate
        if (!currentQuestion.id) { // only create the set once
          if (this.newQuestionPromises.length === 0) {
            this.newQuestionPromises.push(this.createNewQuestion(currentQuestion))
          } else { // if you make additional changed after you have started creating the set then wait until it is created to update it
            Promise.all(this.newQuestionPromises).then(() => {
              this.updateQuestion(this.state.currentQuestion)
            })
          }
        } else {
          this.updateQuestion(currentQuestion)
        }
      }
    }
  }

  createNewSet(set) {
    this.setState({ isSaving: true, unableToSave: false })
    const nowDate = new Date()
    const requestData = {
      ...set,
      clientModified: nowDate,
      userCreated: nowDate,
      lastEditedAt: nowDate,
    }
    return this.props.createNewSet(requestData).then((response) => {
      this.props.history.push(`/seteditor/${response.id}${this.props.match.params.output ? '/output' : ''}`)
      const { currentSet } = this.state
      this.setState({
        isSaving: false,
        unableToSave: false,
        currentSet: { ...currentSet, id: response.id },
      })
      if (response.repo) {
        this.props.updateSetLock(this.props.match.params.id)
        this.setState({ userLockedSet: true })
      }
      return null
    })
      .catch(() => {
        this.newSetPromises = [] // reset promises so attempts to create set again
        this.setState({ isSaving: false, unableToSave: true })
      })
  }

  createNewQuestion(question) {
    this.setState({ isSaving: true, unableToSave: false })
    const nowDate = new Date()
    const requestData = {
      ...question,
      clientModified: nowDate,
      userCreated: nowDate,
      lastEditedAt: nowDate,
    }
    return this.props.createNewQuestion(requestData).then((response) => {
      this.props.history.push(`/editor/${response.id}${this.props.match.params.output ? '/output' : ''}`)
      const { currentQuestion } = this.state
      this.setState({
        isSaving: false,
        unableToSave: false,
        currentQuestion: { ...currentQuestion, id: response.id },
      })
      if (response.repo) {
        this.props.updateQuestionLock(this.props.match.params.id)
        this.setState({ userLockedSet: true })
      }
      return null
    })
      .catch(() => {
        this.newQuestionPromises = []
        this.setState({ isSaving: false, unableToSave: true })
      })
  }

  updateQuestion(question) {
    if (question && question.id) {
      this.setState({ isSaving: true, unableToSave: false })
      return this.props.updateQuestionContent(question, question.id).then((response) => {
        if (response === 'failed') {
          this.setState({ isSaving: false, unableToSave: true })
        } else {
          this.setState({ isSaving: false, unableToSave: false })
        }
      })
    }
  }

  updateSet(set) {
    if (set && set.id) { // don't try and update with changes made during create set request that failed
      this.setState({ isSaving: true, unableToSave: false })
      return this.props.updateSetContent(set, set.id).then((response) => {
        if (response === 'failed') {
          this.setState({ isSaving: false, unableToSave: true })
        } else {
          this.setState({ isSaving: false, unableToSave: false })
        }
      })
    }
  }

  // On PM Doc update

  notThrottledDocUpdate(doc) { // parses the whole set TODO cleanup shared logic between not throttled and throttled doc update functions
    if (!this.props.itemIsLockedByAnotherUser) {
      if (!this.state.userHasMadeEdit) {
        this.lockItem()
        this.setState({ userHasMadeEdit: true }) // make sure initial doc changes on load do not trigger autosave e.g. FS calcs
      }
      let questionOrderArray
      const questions = []
      let activeQuestionObj
      const isIndependentQuestion = !this.state.isSetEditor
      doc.forEach((node) => {
        if (node.type.name === 'question') {
          if (!questionOrderArray) {
            questionOrderArray = node.attrs.questionOrderArray
          }
          const question = convertPMNodeToQuestionObject(node, isIndependentQuestion)
          if (question) {
            questions.push(question)
          }
          if (node.attrs.activeslide) {
            activeQuestionObj = question
            this.setState({ activeQuestionId: question.questionId, activeQuestion: question })
          }
        }
      })
      if (this.state.isSetEditor) {
        const sortedQuestions = []
        questions.forEach((question) => {
          sortedQuestions[questionOrderArray.indexOf(question.questionId)] = question
        })
        const { currentSet } = this.state
        const updatedSetObject = { ...currentSet, questions: sortedQuestions }
        if (this.props.inSharedRepo && this.props.user && this.state.doc) {
          updatedSetObject.lastEditedBy = this.props.user.id
        }
        this.setState({ currentSet: updatedSetObject })
        if (!this.state.doc) {
          // console.log('dont save on first doc load')
        } else {
          this.saveChanges()
        }
        this.setState({ doc }) // for dev out put
      } else { // question editor
        const updatedQuestionObject = activeQuestionObj
        if (this.props.inSharedRepo && this.props.user && this.state.doc) {
          updatedQuestionObject.lastEditedBy = this.props.user.id
        }
        updatedQuestionObject.repo = this.state.currentQuestion.repo
        updatedQuestionObject.archived = false
        updatedQuestionObject.createdOnMobile = false
        updatedQuestionObject.id = this.state.currentQuestion.id
        this.setState({ currentQuestion: updatedQuestionObject, activeQuestion: updatedQuestionObject })
        if (!this.state.doc) {
          // console.log('dont save on first doc load')
        } else {
          this.saveChanges()
        }
        this.setState({ doc }) // for dev out put
      }
    }
  }

  onDocUpdate(doc, addToHistory) { // only parses active question
    if (!this.props.itemIsLockedByAnotherUser) {
      if (!this.state.userHasMadeEdit && addToHistory !== false) {
        this.lockItem()
        this.setState({ userHasMadeEdit: true }) // make sure initial doc changes on load do not trigger autosave e.g. FS calcs
      }
      const questions = []
      let activeQuestionObj
      const isIndependentQuestion = !this.state.isSetEditor
      doc.forEach((node) => {
        if (node.type.name === 'question') {
          if (node.attrs.activeslide) {
            activeQuestionObj = convertPMNodeToQuestionObject(node, isIndependentQuestion)
          }
        }
      })
      if (this.state.isSetEditor && activeQuestionObj) {
        this.state.currentSet.questions.forEach((question) => {
          if (question.questionId !== activeQuestionObj.questionId) {
            questions.push(question)
          } else {
            questions.push(activeQuestionObj)
          }
        })
        const { currentSet } = this.state
        const updatedSetObject = { ...currentSet, questions }
        if (this.props.inSharedRepo && this.props.user && this.state.userHasMadeEdit) {
          updatedSetObject.lastEditedBy = this.props.user.id
        }
        this.setState({ currentSet: updatedSetObject, activeQuestion: activeQuestionObj })
        if (!this.state.doc) {
          // console.log('dont save on first doc load')
        } else if (this.state.userHasMadeEdit) {
          this.saveChanges()
        }
      } else if (!this.state.isSetEditor) { // questionEditor
        const { currentQuestion } = this.state
        const updatedQuestionObject = { ...currentQuestion, ...activeQuestionObj }
        if (this.props.inSharedRepo && this.props.user && this.state.userHasMadeEdit) {
          updatedQuestionObject.lastEditedBy = this.props.user.id
        }
        updatedQuestionObject.repo = this.state.currentQuestion.repo
        updatedQuestionObject.archived = false
        updatedQuestionObject.createdOnMobile = false
        updatedQuestionObject.id = this.state.currentQuestion.id

        this.setState({ currentQuestion: updatedQuestionObject, activeQuestion: updatedQuestionObject })

        if (this.state.doc && this.state.userHasMadeEdit) { // console.log('dont save on first doc load')
          this.saveChanges()
        }
      }
    }
    this.setState({ doc }) // for dev out put
  }

  updateQuestionOrder(fromIndex, toIndex) {
    this.lockItem()
    reorderSetQuestions(fromIndex, toIndex)
    setTimeout(() => { // Start the timer
      this.scrollActiveSlideIntoView()
    }, 10)
  }

  showArchiveSetModal() {
    this.props.showConfirmModal(this.state.currentSet.name, this.archiveSet, 'trash')
  }

  archiveSet() {
    const nowDate = new Date()
    const requestData = {
      ...this.state.currentSet,
      clientModified: nowDate,
      archived: true,
    }
    return this.props.updateSetMeta(requestData, this.state.currentSet.id).then(() => {
      this.props.hideModal()
      this.props.history.push('/library')
    })
  }

  handleKeyDown(e) {
    if (window.location.hash !== '#upgrade' && window.location.hash !== '#upgrade-payment') {
      if (e.keyCode === 9) { // tab key
        e.preventDefault()
        handleEditorTabKeypress(this.focusActiveQuestionInSlideList)
      } else if (e.shiftKey && e.keyCode === 13) { // shift enter
        const { anchor } = window.view.state.selection
        const { doc } = window.view.state

        doc.descendants((node, pos) => {
          const hasAnchor = anchor >= pos && anchor <= (pos + node.nodeSize)
          if (hasAnchor && node.type.name === 'choice') {
            if (node.attrs.correct === false || node.attrs.isTouched === false) {
              setChoiceToCorrect(node, pos)
            }
          }
        })
      }
    }
  }

  updateQuestionFontSizes(perfectCombo) {
    if (perfectCombo.bodyFontSize !== this.state.perfectCombo.bodyFontSize || perfectCombo.choiceFontSize !== this.state.perfectCombo.choiceFontSize) {
      this.setState({ perfectCombo })
      updateQuestionFontSizes(perfectCombo.bodyFontSize, perfectCombo.choiceFontSize)
    }
  }

  updateQuestionMediaDynamicHeight(questionMediaDynamicHeight) {
    if (questionMediaDynamicHeight !== this.state.questionMediaDynamicHeight) {
      this.setState({ questionMediaDynamicHeight })
      updateQuestionMediaDynamicHeight(questionMediaDynamicHeight)
    }
  }

  setActiveQuestion(questionId, addToHistory) {
    hideMenu()
    if (!this.props.itemIsLockedByAnotherUser) {
      setActiveQuestion(questionId, addToHistory)
    }
    this.setState({ activeQuestionId: questionId, perfectCombo: {}, questionMediaDynamicHeight: null })
    const slide = document.getElementById(`draggableSlide_${questionId}`)
    if (slide) {
      slide.focus()
    }
    if (this.props.itemIsLockedByAnotherUser) {
      const { currentSet } = this.state
      const activeQuestionIndex = findIndex(currentSet.questions, (setQuestion) => setQuestion.questionId === questionId)
      this.setState({ activeQuestion: currentSet.questions[activeQuestionIndex] })
    }
  }

  onAddQuestionToSet(newQuestionId) { // trigger doc update
    hideMenu()
    this.lockItem()
    this.setState({ activeQuestionId: newQuestionId, perfectCombo: {}, questionMediaDynamicHeight: null })
    this.notThrottledDocUpdate(window.view.state.doc)
    const slide = document.getElementById(`draggableSlide_${newQuestionId}`)
    if (slide) {
      slide.focus()
    } else {
      setTimeout(() => { // Start the timer
        const slide = document.getElementById(`draggableSlide_${newQuestionId}`)
        if (slide) {
          slide.focus()
        }
      }, 1)
    }
  }

  onDeleteActiveQuestion() {
    this.lockItem()
    this.notThrottledDocUpdate(window.view.state.doc)
    this.focusActiveQuestionInSlideList()
  }

  focusActiveQuestionInSlideList(questionId) {
    const slide = document.getElementById(`draggableSlide_${questionId || this.state.activeQuestionId}`)
    if (slide) {
      slide.focus()
    }
  }

  scrollActiveSlideIntoView(questionId) {
    const slide = document.getElementById(`draggableSlide_${questionId || this.state.activeQuestionId}`)
    if (slide) {
      const slideRect = slide.getBoundingClientRect()
      const offset = 41 // for header
      if (!(slideRect.top >= offset && slideRect.bottom <= window.innerHeight)) {
        slide.scrollIntoView({ block: 'end', behavior: 'instant' })
      }
    }
  }

  handleSlideListContextMenuClick(e, data) {
    hideMenu()
    // context menu sets active question so no need to pass question to duplicate etc
    if (data.action === 'NewQuestion') {
      addQuestionToSet(this.onAddQuestionToSet)
    } else if (data.action === 'Duplicate') {
      duplicateActiveQuestion(this.onAddQuestionToSet)
    } else if (data.action === 'Cut') {
      this.cutQuestion(data.question)
    } else if (data.action === 'Copy') {
      copyQuestion(data.question)
      const notificationMessage = 'to Clipboard'
      this.props.showNotification(notificationMessage, 'Copied', 'default')
    } else if (data.action === 'Paste') {
      this.pasteQuestion()
    } else if (data.action === 'Delete') {
      deleteActiveQuestion(this.onDeleteActiveQuestion)
    } else if (data.action === 'MoveUp') {
      this.moveSlideUp(data.questionIndex)
    } else if (data.action === 'MoveDown') {
      this.moveSlideDown((data.questionIndex))
    }
  }

  cutQuestion(question) {
    this.lockItem()
    copyQuestion(question)
    deleteActiveQuestion(this.onDeleteActiveQuestion)
  }

  pasteQuestion() {
    this.lockItem()
    const copiedQuestion = JSON.parse(localStorage.getItem('copiedQuestion'))
    if (copiedQuestion) {
      addQuestionToSet(this.onAddQuestionToSet, copiedQuestion)
    }
  }

  moveSlideUp(origionalIndex) {
    const newIndex = Math.max(origionalIndex - 1, 0)
    this.updateQuestionOrder(origionalIndex, newIndex)
    setTimeout(() => { // Start the timer
      this.focusActiveQuestionInSlideList()
    }, 10)
  }

  moveSlideDown(origionalIndex) {
    const newIndex = Math.min(origionalIndex + 1, this.state.currentSet.questions.length - 1)
    this.updateQuestionOrder(origionalIndex, newIndex)
    setTimeout(() => { // Start the timer
      this.focusActiveQuestionInSlideList()
    }, 10)
  }

  incrementActiveQuestionIndex(direction) { // for arrowing through slide list
    const { activeQuestionId } = this.state
    const questionOrderArray = getQuestionOrderArray()
    const currentIndex = questionOrderArray.indexOf(activeQuestionId)
    let newIndex
    if (direction === 'up') {
      newIndex = Math.max(0, currentIndex - 1)
    } else if (direction === 'end') {
      newIndex = Math.min(questionOrderArray.length - 1)
    } else if (direction === 'down') {
      newIndex = Math.min(questionOrderArray.length - 1, currentIndex + 1)
    } else if (direction === 'start') {
      newIndex = 0
    }
    const newActiveQuestionId = questionOrderArray[newIndex]
    this.setActiveQuestion(newActiveQuestionId)
  }

  handleSlideListKeyDown(e) {
    hideMenu()
    let maxNumOfQuestionsReached = false
    if (this.props.questionsInSetLimit && this.state.currentSet && this.state.currentSet.questions.length >= this.props.questionsInSetLimit) {
      maxNumOfQuestionsReached = true
    }
    if (!editorIsFocused() && !this.props.isViewOnly) {
      if ((e.metaKey || e.ctrlKey) && e.keyCode === 90) { // control z
        e.preventDefault()
        e.stopPropagation()
        undo(window.view.state, window.view.dispatch, window.view)
      } else if ((e.metaKey || e.ctrlKey) && e.keyCode === 89) { // control y
        e.preventDefault()
        e.stopPropagation()
        redo(window.view.state, window.view.dispatch, window.view)
      } else if (!(e.metaKey || e.ctrlKey) && e.keyCode === 40) { // down arrow
        e.preventDefault()// prevent scrolling container
        if (e.shiftKey) {
          this.incrementActiveQuestionIndex('end')
        } else {
          this.incrementActiveQuestionIndex('down')
        }
      } else if (!(e.metaKey || e.ctrlKey) && e.keyCode === 38) { // up arrow
        e.preventDefault()
        if (e.shiftKey) {
          this.incrementActiveQuestionIndex('start')
        } else {
          this.incrementActiveQuestionIndex('up')
        }
      }
      const slideFocused = slideListSlideIsFocused()
      if (slideFocused) {
        if ((e.metaKey || e.ctrlKey) && e.keyCode === 67) {
          copyQuestion(this.state.activeQuestion)
          const notificationMessage = 'to Clipboard'
          this.props.showNotification(notificationMessage, 'Copied', 'default')
        } else if ((e.metaKey || e.ctrlKey) && e.keyCode === 86 && !maxNumOfQuestionsReached) {
          e.preventDefault()
          this.pasteQuestion()
        } else if ((e.metaKey || e.ctrlKey) && e.keyCode === 88) {
          this.cutQuestion(this.state.activeQuestion)
        } else if ((e.metaKey || e.ctrlKey) && e.keyCode === 68) {
          e.preventDefault()
          if (!maxNumOfQuestionsReached) {
            duplicateActiveQuestion(this.onAddQuestionToSet)
          }
        } else if ((e.metaKey || e.ctrlKey) && !e.shiftKey && e.keyCode === 40) {
          e.preventDefault()
          const { activeQuestionId } = this.state
          const questionOrderArray = getQuestionOrderArray()
          const currentIndex = questionOrderArray.indexOf(activeQuestionId)
          this.moveSlideDown(currentIndex)
        } else if ((e.metaKey || e.ctrlKey) && !e.shiftKey && e.keyCode === 38) { // move slide up
          e.preventDefault()
          const { activeQuestionId } = this.state
          const questionOrderArray = getQuestionOrderArray()
          const currentIndex = questionOrderArray.indexOf(activeQuestionId)
          this.moveSlideUp(currentIndex)
        } else if ((e.metaKey || e.ctrlKey) && e.shiftKey && e.keyCode === 40) { // move to the bottom
          e.preventDefault()
          const { activeQuestionId } = this.state
          const questionOrderArray = getQuestionOrderArray()
          const currentIndex = questionOrderArray.indexOf(activeQuestionId)
          const newIndex = questionOrderArray.length - 1
          this.updateQuestionOrder(currentIndex, newIndex)
        } else if ((e.metaKey || e.ctrlKey) && e.shiftKey && e.keyCode === 38) {
          e.preventDefault()
          const { activeQuestionId } = this.state
          const questionOrderArray = getQuestionOrderArray()
          const currentIndex = questionOrderArray.indexOf(activeQuestionId)
          const newIndex = 0
          this.updateQuestionOrder(currentIndex, newIndex)
        } else if (e.keyCode === 46 || e.keyCode === 8) { // delete key, delete question
          e.preventDefault()
          deleteActiveQuestion(this.onDeleteActiveQuestion)
        } else if (e.keyCode === 13 && !maxNumOfQuestionsReached) { // enter key
          addQuestionToSet(this.onAddQuestionToSet)
        }
      }
    }
  }

  /// /Question Editor Stuff

  convertQuestionIntoSet() {
    this.props.convertQuestionIntoSet(this.state.currentQuestion).then((response) => {
      this.props.history.push(`/seteditor/${response.id}`)
      window.location.reload()
    })
  }

  newQuestionEditorTab() {
    newQuestion(this.state.currentQuestion.folder, this.state.currentQuestion.repo)
  }

  copyQuestionToClipboard(question) {
    copyQuestion(question)
    const notificationMessage = 'to Clipboard'
    this.props.showNotification(notificationMessage, 'Copied', 'default')
  }

  showArchiveQuestionModal() {
    this.props.showConfirmModal(this.state.currentQuestion.body, this.archiveQuestion, 'trash')
  }

  archiveQuestion() {
    const nowDate = new Date()
    const requestData = {
      ...this.state.currentQuestion,
      clientModified: nowDate,
      archived: true,
    }
    return this.props.updateQuestionMeta(requestData, this.state.currentQuestion.id).then(() => {
      this.props.hideModal()
      this.props.history.push('/library')
    })
  }

  render() {
    const {
      activeQuestion, currentSet, isSetEditor, currentQuestion,
    } = this.state

    let documentTitle = 'Set Editor - Plickers'
    if (currentSet) {
      documentTitle = `${currentSet.name} - Plickers`
    } else if (currentQuestion) {
      documentTitle = currentQuestion.body || 'Untitled Question'
    }

    const showOutput = !!this.props.match.params.output // show dev output panel
    let video = null // think we can get rid of this
    if (activeQuestion && activeQuestion.media && activeQuestion.media.type === 'video') {
      video = activeQuestion.media
    }

    let isViewOnly = false
    if (this.props.parentRepo && (this.props.parentRepo.role === 'viewer' || this.props.parentRepo.role === 'consumer')) {
      isViewOnly = true
    }

    let maxNumOfQuestionsReached = false
    if (this.props.questionsInSetLimit && currentSet && currentSet.questions.length >= this.props.questionsInSetLimit) {
      maxNumOfQuestionsReached = true
    }

    if (currentSet || currentQuestion) {
      return (
        <DocumentTitle title={documentTitle}>
          <React.Fragment>
            {isSetEditor && this.state.showImportQuestionsModal && (
            <ImportQuestionsContainer
              hideModal={() => { this.setState({ showImportQuestionsModal: false }) }}
              reloadEditor={this.reloadEditor}
              setId={this.props.currentSet ? this.props.currentSet.id : 'newSet'}
              currentSet={this.props.currentSet}
            />
            )}

            <RichEditorPage
              key={this.state.richEditorKey}
              reloadEditor={this.reloadEditor}
              isSetEditor={this.state.isSetEditor}
              questionsInSetLimit={this.props.questionsInSetLimit}
              maxNumOfQuestionsReached={maxNumOfQuestionsReached}
              focusActiveQuestionInSlideList={this.focusActiveQuestionInSlideList}
              isViewOnly={isViewOnly}
              isPublicConsumer={this.props.isPublicConsumer}
              isPublicAuthor={this.props.isPublicAuthor}
              inSharedRepo={this.props.inSharedRepo}
              itemIsLockedByAnotherUser={this.props.itemIsLockedByAnotherUser}
              makeACopySet={this.props.makeACopySet}
              makeACopyQuestion={this.props.makeACopyQuestion}
              copyQuestionToClipboard={this.copyQuestionToClipboard}
              onDocUpdate={this.onDocUpdate}
              notThrottledDocUpdate={this.notThrottledDocUpdate}
              activeQuestion={this.state.activeQuestion}
              currentSet={this.state.currentSet}
              currentQuestion={this.state.currentQuestion}
              showOutput={showOutput}
              saveChanges={this.saveChanges}
              isSaving={this.state.isSaving}
              unableToSave={this.state.unableToSave}
              video={video}
              setActiveQuestion={this.setActiveQuestion}
              activeQuestionId={this.state.activeQuestionId}
              updateSetTitle={this.updateSetTitle}
              uploadNewImageToCloudinary={this.props.uploadNewImageToCloudinary}
              addQuestionImage={this.props.addQuestionImage}
              showNotification={this.props.showNotification}
              updateQuestionOrder={this.updateQuestionOrder}
              showRepoSelectionModal={this.props.showRepoSelectionModal}
              parentRepo={this.props.parentRepo}
              currentUserPermission={this.props.currentUserPermission}
              lock={this.props.lock}
              permissions={this.props.permissions}
              onAddQuestionToSet={this.onAddQuestionToSet}
              onDeleteActiveQuestion={this.onDeleteActiveQuestion}
              handleSlideListContextMenuClick={this.handleSlideListContextMenuClick}
              showImportQuestionsModal={() => { this.setState({ showImportQuestionsModal: true }) }}
              handleSlideListKeyDown={this.handleSlideListKeyDown}
              showArchiveSetModal={this.showArchiveSetModal}
              showArchiveQuestionModal={this.showArchiveQuestionModal}
              showDuplicateAndEditModal={() => { this.props.showDuplicateAndEditModal(this.state.currentSet) }}
              convertQuestionIntoSet={this.convertQuestionIntoSet}
              newQuestionEditorTab={this.newQuestionEditorTab}
              service={this.props.service}
              user={this.props.user}
              showAdvancedEquationButton={this.props.showAdvancedEquationButton}
              disableFastFractionInputRule={this.props.disableFastFractionInputRule}
              toggleDisableFastFractionInputRule={this.toggleDisableFastFractionInputRule}
              cookieConsents={this.props.cookieConsents}
            />

            <div className='editorGhostElementsContainer'>
              <FontSizeCalculatorComponent
                question={activeQuestion}
                updatePerfectCombo={(perfectCombo) => { this.updateQuestionFontSizes(perfectCombo) }}
                updateQuestionMediaDynamicHeight={(questionMediaDynamicHeight) => { this.updateQuestionMediaDynamicHeight(questionMediaDynamicHeight) }}
                mediaRatio='MM'
              />
            </div>

            {showOutput && (
            <div className='editorContainerWithOutput-outputContainer'>
              <div className='editorContainerWithOutput-outputSection editorContainerWithOutput-outputSection--questionJSON'>
                <div className='editorContainerWithOutput-outputSection-title'>
                  Active Question
                </div>
                <pre>
                  <code>
                    {JSON.stringify(this.state.activeQuestion, null, 2)}
                  </code>
                </pre>
              </div>
              <div className='editorContainerWithOutput-outputSection editorContainerWithOutput-outputSection--doc'>
                <div className='editorContainerWithOutput-outputSection-title'>
                  ProseMirror Doc
                </div>
                <pre>
                  <code>
                    {JSON.stringify(this.state.doc, null, 2)}
                  </code>
                </pre>
              </div>
              <div className='editorContainerWithOutput-outputSection editorContainerWithOutput-outputSection--setJSON'>
                <div className='editorContainerWithOutput-outputSection-title'>
                  Set
                </div>
                <pre>
                  <code>
                    {JSON.stringify(this.state.currentSet, null, 2)}
                  </code>
                </pre>
              </div>
            </div>
            )}
          </React.Fragment>
        </DocumentTitle>
      )
    } return null // if no set or question
  }
}

function mapStateToProps(state, ownProps) {
  const currentSet = find(state.sets, { id: ownProps.match.params.id })
  const currentQuestion = find(state.questions, { id: ownProps.match.params.id })
  let parentRepo
  let permissions
  let lock = null
  let itemIsLockedByAnotherUser = false
  let repoId
  let currentUserPermission
  if (currentSet && currentSet.repo) {
    repoId = currentSet.repo
  } else if (currentQuestion && currentQuestion.repo) {
    repoId = currentQuestion.repo
  } else if (typeof window.repo === 'string') { // Prevent invalid folder value
    repoId = window.repo
  }

  if (repoId && (currentSet || currentQuestion)) {
    parentRepo = find(state.repos, { id: repoId })
    const repoLocks = state.locks[repoId]
    if (currentSet) {
      lock = find(repoLocks, (lock) => lock.item === currentSet.id)
    } else if (currentQuestion) {
      lock = find(repoLocks, (lock) => lock.item === currentQuestion.id)
    }
    if (lock && state.user) {
      if (lock.user !== state.user.id) {
        itemIsLockedByAnotherUser = true
      }
    }
    permissions = state.permissions[repoId]
    const currentUser = state.user
    if (permissions && currentUser.id) {
      const indexOfCurrentUserPermission = permissions.findIndex((permission) => {
        if (permission.user) {
          return permission.user.id === currentUser.id
        } return permission.originalEmail === currentUser.email
      })
      currentUserPermission = permissions[indexOfCurrentUserPermission]
    }
  }

  let isViewOnly
  if (ownProps.match.params.id === 'newSet' || ownProps.match.params.id === 'newquestion') {
    isViewOnly = false
  }
  if ((currentSet && !currentSet.repo) || (currentQuestion && !currentQuestion.repo)) {
    isViewOnly = false
  } else if (parentRepo && (parentRepo.role === 'viewer' || parentRepo.role === 'consumer')) {
    isViewOnly = true
  } else if (parentRepo) {
    isViewOnly = false
  }
  let isPublicConsumer = false
  if (parentRepo && parentRepo.role === 'consumer') {
    isPublicConsumer = true
  }
  let isPublicAuthor = false
  if (parentRepo && parentRepo.role === 'author') {
    isPublicAuthor = true
  }
  let inSharedRepo = false
  if (parentRepo && parentRepo.shared) {
    inSharedRepo = true
  }
  let showAdvancedEquationButton = false
  let disableFastFractionInputRule = false
  if (state.userConfig) {
    if (state.userConfig.displayAdvancedEquationButtonInEditor) {
      showAdvancedEquationButton = true
    }
    if (state.userConfig.disableFastFractionInputRule) {
      disableFastFractionInputRule = true
    }
  }
  return {
    user: state.user,
    showAdvancedEquationButton,
    disableFastFractionInputRule,
    isViewOnly,
    isPublicConsumer,
    isPublicAuthor,
    inSharedRepo,
    currentSet,
    currentQuestion,
    parentRepo,
    currentUserPermission,
    itemIsLockedByAnotherUser,
    lock,
    permissions,
    questionsInSetLimit: state.planRestrictions.questionsInSetLimit,
    service: state.service,
    cookieConsents: state.cookieConsents,
  }
}

export default withRouter(connect(
  mapStateToProps,
  {
    createNewSet,
    createNewQuestion,
    updateSetContent,
    updateQuestionContent,
    updateQuestionMeta,
    updateSetMeta,
    convertQuestionIntoSet,
    uploadNewImageToCloudinary,
    addQuestionImage,
    showNotification,
    makeACopySet,
    makeACopyQuestion,
    showRepoSelectionModal,
    updateSetLock,
    deleteLockForSet,
    synchronousDeleteLockForSet,
    updateQuestionLock,
    deleteLockForQuestion,
    synchronousDeleteLockForQuestion,
    fetchLocksForRepo,
    showConfirmModal,
    showDuplicateAndEditModal,
    hideModal,
    updateUserConfig,
  },
)(RichEditorContainer))
