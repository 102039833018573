export default function (state = [], action) {
  switch (action.type) {
    case 'UPDATE_COOKIE_CONSENT':
      return action.cookieConsents

    case 'LOGOUT':
      return []

    default:
      return state
  }
}
