import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import store from '../store'
import PageReloadModal from '../components/modals/PageReloadModal'

function isActualPage(location, pageUrlSnippet) {
  return location.pathname.indexOf(pageUrlSnippet) > -1
}

function isReloadAllowed(location) {
  return (
    !isActualPage(location, '/slideservice/') &&
    !isActualPage(location, '/migration-service/')
  )
}

function shouldAlwaysReload(alwaysReloadOnPagesList, location) {
  return alwaysReloadOnPagesList &&
    alwaysReloadOnPagesList.length > 0 &&
    alwaysReloadOnPagesList.some((pageUrlSnippet) => isActualPage(location, pageUrlSnippet))
}

function isTrueForLocalStorage(condition) {
  const { key, compareValueTo, destroyAfterUse } = condition
  const value = localStorage.getItem(key)
  if (destroyAfterUse) {
    localStorage.removeItem(key)
  }
  return value === compareValueTo
}

function isTrueForWindow(condition) {
  const { key, compareValueTo, destroyAfterUse } = condition
  const value = window[key]
  if (destroyAfterUse) {
    delete window[key]
  }
  return value === compareValueTo
}

function shouldReload(condition, location) {
  if (!condition || _.isEmpty(condition)) {
    return true
  }

  const { dataStorage, reloadAlwaysOnPages } = condition

  if (dataStorage === 'localStorage') {
    return isTrueForLocalStorage(condition) || shouldAlwaysReload(reloadAlwaysOnPages, location)
  }

  if (dataStorage === 'window') {
    return isTrueForWindow(condition) || shouldAlwaysReload(reloadAlwaysOnPages, location)
  }
}

function PageReloadContainer(props) {
  const { pageReload, location } = props
  const [showModal, setShowModal] = useState(false)
  const [modalMessage, setModalMessage] = useState('Please reload the page to apply the changes.')
  const [modalActionWord, setModalActionWord] = useState('Page Reload Required')

  useEffect(() => {
    const {
      strategy,
      condition,
      modalMessage,
      modalActionWord,
    } = pageReload

    if (!_.isEmpty(pageReload) && isReloadAllowed(location)) {
      if (strategy === 'immediate' && shouldReload(condition, location)) {
        reloadPage()
      }

      if (strategy === 'userAction' && shouldReload(condition, location)) {
        setShowModal(true)
        if (modalMessage) {
          setModalMessage(modalMessage)
        }
        if (modalActionWord) {
          setModalActionWord(modalActionWord)
        }
      }
    }
  }, [pageReload])

  function reloadPage() {
    store.dispatch({ type: 'PAGE_RELOADED' })
    window.location.reload()
  }

  function dismissModal() {
    setShowModal(false)
  }

  return (
    showModal ? (
      <PageReloadModal
        message={modalMessage}
        actionWord={modalActionWord}
        reloadPage={() => reloadPage()}
        dismissModal={() => dismissModal()}
      />
    ) :
      <React.Fragment />
  )
}

export default withRouter(connect(
  (state) => ({
    pageReload: state.pageReload,
  }),
  {},
)(PageReloadContainer))
